import styled from "react-emotion";

const Wrap = styled.div`
    position: fixed;
    left: 0;
    z-index: 1000;
    bottom: 1.5em; // 24px
    margin-left: -100%;
    font-size: 16px;
    transition: margin-left linear 0.7s;

    @media screen and (max-width: 767px) {
        width: calc(100% - 48px);
    }
    @media screen and (min-width: 768px) {
        width: 21.5625em; // 345px
    }

    ${props =>
        props.isOpen &&
        `
            margin-left: 1.5em; // 24px
            animation: notice-show 0.7s linear;    
        `};

    @keyframes notice-show {
        from {
            margin-left: -100%;
        }
        to {
            margin-left: 24px;
        }
    }
`;

const Inner = styled.div`
    overflow: auto;
    max-height: calc(100vh - 48px);
    padding: 1em; // 16px
    border-radius: 0.25em; // 4px
    box-sizing: border-box;
    word-break: break-word;
    word-wrap: break-word;
    -webkit-hyphens: manual;
    -moz-hyphens: manual;
    -ms-hyphens: manual;
    -o-hyphens: manual;
    hyphens: manual;

    a {
        text-decoration: none;
        box-shadow: 0 1px 0 0 currentColor;
        transition: box-shadow ease-out 0.1s;

        &:hover {
            box-shadow: none;
        }
    }

    ${props =>
        props.design === "light"
            ? `
                background: #fff;
                color: #000;
                box-shadow: 0 4px 24px rgba(114, 128, 170, 0.32);

                a {
                    color: #0067f2;
                }
            `
            : `
                background: #161925;
                color: #fff;
                opacity: 0.95;

                a {
                    color: currentColor;
                }
    
            `};
`;

const Message = styled.div`
    padding-bottom: 1.143em; // 16px

    &,
    p {
        line-height: 1.429; // 20px

        @media screen and (max-width: 1023px) {
            font-size: 13px;
        }
        @media screen and (min-width: 1024px) {
            font-size: 14px;
        }
    }
    p {
        margin: 0;
        padding: 0;
    }
`;

const Button = styled.button`
    cursor: pointer;
    width: 100%;
    padding: 1em 2em; // 12px 24px
    background-color: #0067f2;
    font-weight: 600;
    font-size: 0.75em; // 12px
    line-height: 1.3334em; // 16px
    letter-spacing: 0.04em;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
    transition: background-color ease-out 0.1s;
    outline: none;

    &:hover,
    &:focus {
        background-color: #0058f2;
    }
    &:active {
        background-color: #0046f2;
    }
`;

export { Wrap, Inner, Message, Button };
