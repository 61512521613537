import React from "react";

import Supplier from "./Supplier";
import Notice from "./Notice";

const Union = Supplier(Notice);

const Main = props => <Union {...props} />;

export default Main;
