import React from "react";
import ReactDOM from "react-dom";

const modalRoot = process.env.SERVER ? null : document.body;

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.el = !process.env.SERVER && document.createElement("section");
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.el);
    }
}

export default Modal;
