import React from "react";
import PropTypes from "prop-types";

import Modal from "./Modal";
import { Wrap, Inner, Message, Button } from "./style";

class Notice extends React.Component {
    constructor(props) {
        super(props);

        const defaultValue = !this.getCookie("cookie_ok");
        this.state = {
            isShow: defaultValue,
            isOpen: defaultValue
        };
    }

    getCookie = name => {
        const matches = document.cookie.match(
            new RegExp(
                "(?:^|; )" +
                    name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
                    "=([^;]*)"
            )
        );
        return Boolean(matches && decodeURIComponent(matches[1]) === "1");
    };

    setCookie = (name, value) => {
        document.cookie =
            encodeURIComponent(name) +
            "=" +
            encodeURIComponent(value) +
            ";path=/";
    };

    handleClick = () => {
        this.setCookie("cookie_ok", "1");

        this.setState({
            isOpen: false
        });

        setTimeout(() => {
            this.setState({
                isShow: false
            });
        }, 700);
    };

    render() {
        const {
            getClientCookiesNotice: {
                getClientCookieNotice: { design, message, button }
            }
        } = this.props;
        const { isShow, isOpen } = this.state;

        if (isShow) {
            return (
                <Modal>
                    <Wrap {...{ isOpen }}>
                        <Inner {...{ design }}>
                            <Message
                                dangerouslySetInnerHTML={{
                                    __html: message
                                }}
                            />
                            <Button
                                onClick={this.handleClick}
                                dangerouslySetInnerHTML={{
                                    __html: button
                                }}
                            />
                        </Inner>
                    </Wrap>
                </Modal>
            );
        } else {
            return null;
        }
    }
}

Notice.propTypes = {
    getClientCookiesNotice: PropTypes.shape({
        getClientCookieNotice: PropTypes.shape({
            design: PropTypes.string,
            message: PropTypes.string,
            button: PropTypes.string
        })
    })
};

export default Notice;
