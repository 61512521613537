import { graphql, compose } from "react-apollo";
import { branch, renderNothing } from "recompose";

import { GetClientCookiesNotice } from "./query";

const withEmptyState = branch(({ getClientCookiesNotice }) => {
    return !getClientCookiesNotice?.getClientCookieNotice?.state;
}, renderNothing);

// for query
const gqlGetClientCookiesNotice = graphql(GetClientCookiesNotice, {
    name: "getClientCookiesNotice",
    options: {
        errorPolicy: false,
        ssr: false
    }
});

export default compose(gqlGetClientCookiesNotice, withEmptyState);
